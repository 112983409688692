import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';


export const styles = (theme: Theme) => createStyles({
    gridContainer: {
        margin: '17em 0 17em 50em ',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(1300)]: {
            margin: '10em 0 10em 35em !important',
        },
        [theme.breakpoints.down(1100)]: {
            margin: '10em 0 10em 25em !important',
        },
        [theme.breakpoints.down(1000)]: {
            margin: '10em 0 10em 17em !important',
            width: '70%',
            marginLeft: '22%',
        },
        [theme.breakpoints.down(701)]: {
          margin: '10em 0 10em 10em !important',
            width: '74%',
            marginLeft: '21%',
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '200px',
            marginTop: '150px',
            '& #replaceText': {
                marginLeft: '-3% !important',
            }
        },
        [theme.breakpoints.down(600)]: {
            '& #replaceText': {
                marginLeft: '-6% !important',
            }
        },
        [theme.breakpoints.down(501)]: {
            width: '78%',
            margin: '5em 0 7em 5.5em !important',
            marginBottom: '85px',
            marginTop: '125px',
            '& #replaceText': {
                marginLeft: '-3% !important',
                width: '37%',
            }
        },
    },
    textContainer: {
        width: '60%',
        alignSelf:'center',
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '0%',
            width: '48%',
        },
        [theme.breakpoints.down(900)]: { width: '43%', },
        [theme.breakpoints.down(769)]: {
            width: '40%',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 500,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '8%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '93px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '18.33px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(501)]: {
                    marginBottom: '53px',
                    '& #text': {
                        fontSize: '18.33px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
            },            '& #text': {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
          width: '45%',
          alignSelf: 'center',
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '0px',
            marginLeft: '5% !important',
            alignSelf: 'center',
            '& #text': {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
    },
    text: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.6px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin: '1em 0 0 0',
        [theme.breakpoints.down(1000)]: {
          fontSize: '16.3px !important',
          lineHeight: '21.35px !important',
        },
        [theme.breakpoints.down(800)]: {
          fontSize: '14.3px !important',
          lineHeight: '18.2px !important',
        },
        [theme.breakpoints.down(600)]: {
          fontSize: '12.3px !important',
          lineHeight: '17.1px !important',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px !important',
            letterSpacing: '0.3px !important',
            lineHeight: '16px !important',
            width: '115% !important',
        },
    },
    bigText: {
        fontSize: '100px',
        color: '#3D4274',
        letterSpacing: '1.7px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin: '0.5em 0 0.5em 0',
        [theme.breakpoints.down(1000)]: { fontSize: '87px', },
        [theme.breakpoints.down(800)]: { fontSize: '79px', },
        [theme.breakpoints.down(600)]: { fontSize: '71px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '68.5px',
            letterSpacing: '-0.3px',
            marginTop: '20px',
            marginBottom: '20px',
        },
    },
    straightLine: {
        borderTop: '1px solid #3D4274',
        background: 'none !important',
        [theme.breakpoints.down(501)]: {
          marginBottom: '1em',
          width: '140%',
        },
    },
    specialText: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '1.7px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin: '1em 0 0 0',
        width: '65%',
        [theme.breakpoints.down(1000)]: {
          fontSize: '15px !important',
          lineHeight: '21.14px !important',
        },
        [theme.breakpoints.down(800)]: {
          fontSize: '13px !important',
          lineHeight: '18px !important',
        },
        [theme.breakpoints.down(600)]: {
          fontSize: '11px !important',
          lineHeight: '16px !important',
        },
        [theme.breakpoints.down(501)]: {
            width: '85% !important',
            fontSize: '9.14px !important',
            letterSpacing: '0.82px !important',
            lineHeight: '14.14px !important',
        },
    },
});
