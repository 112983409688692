// React imports
import React, { useState } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Carousel from '@cuenca-mx/tamayo/dist/Carousel';
import ImageCarousel from 'components/ImageCarousel';

// local imports
import { styles } from './NewClients.style';

function NewClients(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;
    const [isVirtual, setVirtual] = useState(true);
    let imagesData;
    if (isFullLicense) {
      imagesData = {
        'virtual': [
            (style) => <ImageCarousel type='full_new_virtual_1' style={style} />,
            (style) => <ImageCarousel type='full_new_virtual_2' style={style} />,
            (style) => <ImageCarousel type='full_new_virtual_3' style={style} />,
            (style) => <ImageCarousel type='full_new_virtual_4' style={style} />,
            (style) => <ImageCarousel type='full_new_virtual_5' style={style} />,
            (style) => <ImageCarousel type='full_new_virtual_6' style={style} />,
        ],
        'fisical': [
            (style) => <ImageCarousel type='full_new_fisical_1' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_2' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_3' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_4' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_5' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_6' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_7' style={style} />,
            (style) => <ImageCarousel type='full_new_fisical_8' style={style} />,
        ]
      };
    } else {
      imagesData = {
        'virtual': [
            (style) => <ImageCarousel type='new_virtual_1' style={style} />,
            (style) => <ImageCarousel type='new_virtual_2' style={style} />,
            (style) => <ImageCarousel type='new_virtual_3' style={style} />,
            (style) => <ImageCarousel type='new_virtual_4' style={style} />,
            (style) => <ImageCarousel type='new_virtual_5' style={style} />,
            (style) => <ImageCarousel type='new_virtual_6' style={style} />,
        ],
        'fisical': [
            (style) => <ImageCarousel type='new_fisical_1' style={style} />,
            (style) => <ImageCarousel type='new_fisical_2' style={style} />,
            (style) => <ImageCarousel type='new_fisical_3' style={style} />,
            (style) => <ImageCarousel type='new_fisical_4' style={style} />,
            (style) => <ImageCarousel type='new_fisical_5' style={style} />,
            (style) => <ImageCarousel type='new_fisical_6' style={style} />,
            (style) => <ImageCarousel type='new_fisical_7' style={style} />,
            (style) => <ImageCarousel type='new_fisical_8' style={style} />,
        ]
      };
    }
    return (
      <Box className={classes.root}>
        <Box className={classes.carousel}>
          { isVirtual && (<Carousel
            dataPerPage={imagesData['virtual'].length}
            dotStyle='virtual'
            carouselSlides={imagesData['virtual']}
            />)}
          { !isVirtual && (<Carousel
              dataPerPage={imagesData['fisical'].length}
              dotStyle={ isFullLicense ? 'fisica-full' : 'fisica'}
              carouselSlides={imagesData['fisical']}
            />)
          }
          <Box className={classes.inline}>
            <p className={isVirtual ? 'active left': 'left'} onClick= {() => setVirtual(true)}>
              VIRTUAL
            </p>
            <p className={isVirtual ? null: 'active'} onClick= {() => setVirtual(false)}>
              FÍSICA
            </p>
          </Box>
        </Box>
        <Box className={classes.textBox}>
          <p className={classes.title}>Clientes nuevos</p>
          <p className={classes.subtitle}>OBTÉN TU TARJETA</p>
          <p className={classes.text}>
            A partir del 6 de mayo podrás ser parte de los nuevos servicios que
            te brindaremos con la alianza con Mastercard y Tarjetas Cuenca.
            Únicamente necesitas descargar la app en <a className={classes.link} href="https://cuenca.com/app" target="_blank">cuenca.com/app</a>,
            ser mayor de edad y tener a la mano tu CURP y podrás abrir tu
            cuenta Nivel 1, que tiene un límite de saldo total de $6,500 pesos
             y en depósitos de $5,000 pesos mensuales.
            Si requieres otro tipo de cuenta, tenemos más opciones para ti,
            solo necesitas tener una identificación oficial: INE, pasaporte
            mexicano, pasaporte extranjero (siempre que tengas CURP),
            tarjeta de residencia o matrícula consular, realizar tu
            primer depósito por $50 pesos o más, que podrás utilizar
            en lo que quieras, ¡y listo!
            Contamos con un sistema interno de valoración para otorgar la
            tarjeta. En caso de no recibirla puedes contactar al equipo de
            soporte.
          </p>
          <p className={classes.text}>
            Contamos con un sistema interno de valoración para otorgar la tarjeta.
            En caso de no recibirla puedes contactar al equipo de soporte.
          </p>
        </Box>
      </Box>
  );
}

export default withStyles(styles)(NewClients);
