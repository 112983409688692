import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    root: {
      fontFamily: 'MuseoSans, sans-serif',
      fontWeight: 300,
      fontSize: '36px',
      color: '#3D4274',
      letterSpacing: '3.21px',
      lineHeight: '50px',
      textAlign: 'center',
      marginLeft: '7em',
      marginRight: '0em',
      marginBottom: '5em',
      marginTop: '5em',
      [theme.breakpoints.down(1350)]: {
        fontSize: '26px',
        marginLeft: '3em',
        marginRight: '3em'
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: '26px',
        letterSpacing: '2.21px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '23px',
        letterSpacing: '1.71px',
        lineHeight: '35px',
      },
      [theme.breakpoints.down(600)]: {
        fontSize: '21.3px',
        textAlign: 'left',
        marginLeft: '5em',
        letterSpacing: '1.21px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down(501)]: {
        fontSize: '17.14px',
        letterSpacing: '0.57px',
        lineHeight: '25.71px'
      },
    },
});
