// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import {styles} from './Header.style';

function Header(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const bars = useMediaQuery('(min-width:1300)');
    const firstDots = useMediaQuery('(min-width:900px)');
    const mobileBreakpoint = useMediaQuery('(min-width:450px)');

    return (
        <Box className={classes.root}>

            <Box className={classes.content}>

                <Box className={classes.textContainer}>
                    <Box className={classes.titleContainer}>
                        <p className='title'>Nuestra</p>
                        <p id='snd' className='title'>travesía</p>

                        <p id='subtitle' style={{
                            width: "65%",
                        }}>
                            Nos aliamos con Mastercard y Cuenca para acercarnos
                            un paso más hacia ti
                        </p>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default withStyles(styles)(Header);
