// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import benefits from 'images/MC_futuros_beneficios.jpg';

import { styles } from './Benefits.style'

function Benefits(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const reduceMargin = useMediaQuery('(min-width:600px)');

    return (
        <Box className={classes.gridContainer}>
            <Box className={classes.textContainer}>
                <p className={classes.subtitle}>
                    Futuros beneficios
                </p>
                <p className={classes.text} style={{
                    marginBottom: reduceMargin ? '1em' : '3em'
                }}>
                    Seguiremos dando pasos para ofrecerte en un futuro:
                </p>
                <p className={classes.text}>
                    Compras a meses sin intereses.
                </p>
                <p className={classes.straightLine}/>
                <p className={classes.text}>
                    Otorgar una línea de crédito de tu cuenta, superior al saldo de la misma.
                </p>
            </Box> 

            <Box id='benefits'>
                <img src={benefits} alt='TarjetasCuenca + MasterCard + Cuenca'/>
            </Box>
        </Box>
    )
}

export default withStyles(styles)(Benefits);
