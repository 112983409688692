import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    gridContainer: {
        margin: '17em 0 17em 28% ',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(1200)]: {
            margin: '15em 0 15em 25% ',
        },
        [theme.breakpoints.down(1000)]: {
            margin: '15em 0 15em 22% ',
        },
        [theme.breakpoints.down(850)]: {
            flexDirection: 'column',
            margin: '13em 0 13em 23% ',
        },
        [theme.breakpoints.down(700)]: {
            margin: '11em 0 7em 23% ',
        },
        [theme.breakpoints.down(501)]: {
            margin: '10em 0 0em 12% ',
        },
        '& #mcVirtual': {
            '& img': {
                height: '100%',
                width: '100%',
                alignSelf: 'center',
                objectFit: 'contain',
                [theme.breakpoints.down(1300)]: {
                    width: '90%',
                },
                [theme.breakpoints.down(1000)]: {
                    width: '90%',
                },
                [theme.breakpoints.down(850)]: {
                    maxWidth: '120%',
                },
                [theme.breakpoints.down(770)]: {
                    maxWidth: '100%',
                },

                [theme.breakpoints.down(650)]: {
                    marginBottom: '50px',
                    maxWidth: '80%',
                },
                [theme.breakpoints.down(501)]: {
                    maxWidth: '100%',
                    width: '80%',
                    marginBottom: '0px',
                    marginTop: '0px',
                    marginLeft: '50px',
                },
            },
        },
    },
    subSectionIndex: {
        fontSize: '18.31px',
        letterSpacing: '1.71px',
        fontWeight: 700,
        color: '#9BA0B7',
        marginBottom: '60px',
        [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
          fontSize: '10.9px',
          letterSpacing: '1.02px',
          lineHeight: '16px',
          marginBottom: '30px'
        },
    },
    textContainer: {
        width: '45%',
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            width: '50%',
            marginRight: '3%',
        },
        [theme.breakpoints.down(850)]: {
            marginLeft: '25%',
            width: '90%',
            marginBottom: '70px',
        },
        [theme.breakpoints.down(769)]: {
            marginBottom: '93px',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 500,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '8%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '93px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(501)]: {
                    marginBottom: '53px',
                    '& #text': {
                        fontSize: '10.86px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
            },            '& #text': {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(501)]: {
            width: '100%',
            marginBottom: '53px',
            marginLeft: '25%',
            '& #text': {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
                width: '75%',
            },
        },
    },
    text: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        fontWeight: 500,
        [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
        [theme.breakpoints.down(850)]: { width: '75% !important' },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            width: '60% !important',
            letterSpacing: '0.34px',
            fontSize: '10.86px',
            lineHeight: '16px',
        },
    },
});
