// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { styles } from './Video.style'

function Video(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/7MOQJDera8c"
            title="La Travesía de Cuenca"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen">
          </iframe>
        </Box>
    )
}

export default withStyles(styles)(Video);
