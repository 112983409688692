import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    gridContainer: {
        margin: '17em 0 17em 28% ',
        display: 'flex',
        flexDirection: 'row',
        '& #logosUnion': {
            '& img': {
                marginLeft: '155%',
                height: 'auto',
                width: '95%',
            },
        },
        [theme.breakpoints.down(1200)]: {
          '& #logosUnion': {
              '& img': {
                  marginLeft: '55% !important',
              }
          }
        },
        [theme.breakpoints.down(1000)]: {
            width: '70%',
            marginLeft: '22%',
            '& #logosUnion': {
                '& img': {
                    marginLeft: '70% !important',
                    height: 'auto !important',
                },
            },
        },
        [theme.breakpoints.down(701)]: {
            width: '71%',
            marginLeft: '21%',
            '& #logosUnion': {
                '& img': {
                    marginLeft: '30% !important',
                }
            }
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '200px',
            marginTop: '150px',
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '85px',
            marginTop: '125px',
            '& #logosUnion': {
                '& img': {
                    marginTop: '85px',
                    marginLeft: '30% !important',
                    width: '60% !important',
                },
            },
        },
        [theme.breakpoints.down(369)]: {
            '& #logosUnion': {
                '& img': {
                    marginTop: '110px',
                },
            },
        },
    },
    textContainer: {
        width: '45%',
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '8%',
        },
        [theme.breakpoints.down(769)]: {
            marginBottom: '93px',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 500,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '8%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '93px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(501)]: {
                    marginBottom: '53px',
                    '& #text': {
                        fontSize: '15px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
            },            '& #text': {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '53px',
            alignSelf: 'center',
            width: '95%',
            '& #text': {
                fontSize: '15px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
                width: '75%',
            },
        },
    },
    subtitle: {
        fontSize: '36px',
        color: '#3D4274',
        letterSpacing: '3.21px',
        lineHeight: '50px',
        fontWeight: 300,
        marginBottom: '80px',
        width: '60%',
        [theme.breakpoints.down(1000)]: {
            fontSize: '32px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '28px',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '23px',
        },
        [theme.breakpoints.down(501)]: {
            width: '100%',
            fontSize: '19.43px',
            letterSpacing: '1.73px',
            lineHeight: '22.86px',
            marginBottom: '40px',
        },
    },
    subSectionIndex: {
        fontWeight: 700,
        fontSize: '18.31px',
        letterSpacing: '1.71px',
        lineHeight: '29px',
        color: '#9BA0B7',
        flexDirection: 'column',
        marginBottom: '80px',
        [theme.breakpoints.down(1000)]: { marginBottom:'4vh', fontSize: '16.3px',},
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.9px',
            letterSpacing: '1.02px',
            lineHeight: '16px',
            marginBottom: '40px',
        },
    },
    text: {
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        fontWeight: 500,
        [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.34px',
            lineHeight: '16px',
        },
    },
});
