import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        height: '100%',
        width: '100vw',
        overflow: 'hidden',
    },
    container: {
        position: 'relative',
    },
    trail: {
        position: 'absolute',
        top: '20em',
        left: 0,
        width: '100vw',
        zIndex: -1,
        '& svg': { width: '100%' },
    },
});
