// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { styles } from './McFisica.style';


import MCClasicaApp from 'images/MC_clasica_app_fisica.jpg';
import MCClasicaAppFull from 'images/MC_virtual_clasica_full.jpg';


function MCFisica(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;

    return(
        <Box className={classes.gridContainer}>
            <Box id='mcFisica'>
                <img src={isFullLicense ? MCClasicaAppFull : MCClasicaApp} alt='TarjetasCuenca + MasterCard + Cuenca'/>
            </Box>

            <Box className={classes.textContainer} >
                <p className={classes.subSectionIndex} style={{
                    marginLeft: '28%',
                    marginTop: '0%',
                }}>
                    ¡CON ESTA ALIANZA SALES GANANDO!
                </p>
                <p className={classes.text} style={{
                    marginTop: '3em',
                    marginBottom: '3em'
                }}>
                    Con tu tarjeta física de crédito Mastercard podrás realizar
                    retiros en efectivo en la red de cajeros de toda la República.
                    Es importante tener en cuenta las comisiones por retiro de
                    efectivo de cada institución bancaria.
                </p>
            </Box>
        </Box>
    )

}

export default withStyles(styles)(MCFisica);
