import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
  root: {
    margin: '0 29em 10em 28%',
    fontFamily: 'MuseoSans, sans-serif',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'auto auto auto auto auto',
    [theme.breakpoints.down(1350)]: { margin: '0 0 10em 28%', },
    [theme.breakpoints.down(1000)]: { margin: '0 4em 10em 28%', },
    [theme.breakpoints.down(769)]: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 5.5em 10em 30%',
    },
    [theme.breakpoints.down(501)]: { margin: '0 5em 10em 6.2em', },
  },
  title: {
    fontWeight:300,
    fontSize: '36px',
    letterSpacing: '3.21px',
    lineHeight: '50px',
    marginTop: '-15px',
    color: theme.palette.primary.main,
    marginBottom: '9vh',
    [theme.breakpoints.down(1000)]: {
      fontSize: '32px',
      marginBottom: '7vh',
    },
    [theme.breakpoints.down(800)]: { fontSize: '28px', },
    [theme.breakpoints.down(600)]: { fontSize: '23px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '19.43px',
      marginBottom: '3vh',
      letterSpacing: '1.43px',
      lineHeight: '22.86px',
    }

  },
  text: {
    fontWeight:500,
    fontSize: '18.3px',
    letterSpacing: '0.7px',
    lineHeight: '26.35px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
    [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
    [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '10.86px',
      marginBottom: '3vh',
      letterSpacing: '0.34px',
      lineHeight: '16px',
    }
  },
  image:{
    [theme.breakpoints.down(1350)]: {
      marginLeft: '80px'
    },
    [theme.breakpoints.down(1000)]: {
      width: '65%',
    },
    [theme.breakpoints.down(769)]: {
      width: '70%',
      marginLeft: '15px',
      margin: '0 0 4em 0px',
    },
    [theme.breakpoints.down(501)]: {
      width: '75%',
      margin: '0 0 4em 0px',
    }
  }
});
