import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: 'MuseoSans, sans-serif',
        fontWeight: '300',
        marginBottom: '150px',
        marginTop: '237px',
        [theme.breakpoints.down(1000)]: {
            width: '100%',
            marginLeft: '22%',
        },
        [theme.breakpoints.down(701)]: {
            width: '71%',
            marginLeft: '21%',
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '100px',
            marginTop: '150px',
        },
        [theme.breakpoints.down(500)]: {
            marginBottom: '85px',
            marginTop: '125px',
            marginLeft: '20%',
        },
    },
    content: {
        marginLeft: '28%',
        [theme.breakpoints.up(1441)]: { maxWidth: '75%', },
        [theme.breakpoints.down(1200)]: { width: '100%', },
        [theme.breakpoints.down(1000)]: {
            width: '100%',
            marginLeft: '0px',
        },
        [theme.breakpoints.down(701)]: {
          marginLeft: '0%',
          width: '100%',
        },
        [theme.breakpoints.down(550)]: {
          marginLeft: '0%',
          width: '100%',
        },
        [theme.breakpoints.down(500)]: { width: '100%',},
    },
    titleContainer: {
        width: '100%', // 1037px
        marginBottom: '4em',
        letterSpacing: '5.06px',
        '& .title': {
            fontSize: '65px',
            color: '#3D4274',
            letterSpacing: '7px',
            marginBottom: '0',
        },
        '& #subtitle': {
            fontSize: '30px',
            fontWeight: 300,
            color: '#3D4274',
            letterSpacing: '1.55px',
            lineHeight: '45px',
            margin: '4em 0 0 0',
        },
        '& #snd': { marginLeft: '2em' },
        [theme.breakpoints.down(1000)]: {
            marginBottom: '85px',
            '& #top': {
                fontSize: '29.7px',
                letterSpacing: '2.11px',
            },
            '& .title': {
                fontSize: '55.4px',
                letterSpacing: '6.34px',
            },
            '& #subtitle': {
                fontSize: '24px',
                letterSpacing: '1.45px',
            },
        },
        [theme.breakpoints.down(701)]: {
            marginBottom: '90px',
            '& #top': {
                fontSize: '28.13px',
                letterSpacing: '2px',
            },
            '& .title': {
                fontSize: '52.24px',
                letterSpacing: '6px',
            },
            '& #snd': { marginLeft: '20px', },
            '& #subtitle': { margin: '2.5em 0 0 0', },
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '60px',
            '& #top': {
                fontSize: '25px',
                letterSpacing: '1.8px',
            },
            '& .title': {
                fontSize: '45px',
                letterSpacing: '5.5px',
            },
            '& #subtitle': {
                fontSize: '24px',
                lineHeight: '35px',
                margin: '3em 0 0 0',
                width: "85% !important",
            },
            '& #trd': { marginLeft: '90px', },
        },
        [theme.breakpoints.down(500)]: {
            '& #top': {
                fontSize: '16.07px',
                letterSpacing: '1.14px',
            },
            '& .title': {
                fontSize: '29.85px',
                letterSpacing: '3.43px',
            },
            '& #subtitle': {
                fontSize: '18px',
                lineHeight: '20px',
                margin: '2em 0 0 0',
                width: "100% !important",
            },
            '& #snd': { marginLeft: '30px', },
            '& #trd': { marginLeft: '75px', },
        },
    },
    textContainer: {
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 300,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '8%',
        },
        [theme.breakpoints.down(769)]: {
            marginBottom: '93px',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 300,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '8%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '93px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(500)]: {
                    marginBottom: '53px',
                    '& #text': {
                        fontSize: '10.86px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
            },            '& #text': {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(500)]: {
            marginBottom: '53px',
            '& #text': {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
                width: '75%',
            },
        },
    }
});
