import React, { useState, useRef, useLayoutEffect } from 'react';

import { useMediaQuery, WithStyles, withStyles } from '@material-ui/core';
import {
    motion,
    useReducedMotion,
    useTransform,
    useViewportScroll
} from 'framer-motion';

import { styles } from './Trail.style';
import TrailSvg from 'images/trail/route.inline.svg';

type TrailProps = {
    children: React.ReactNode;
};

const Trail = (
    props: TrailProps & WithStyles<typeof styles>
) => {
    const { classes, children, showMobile } = props;
    const [elementTop, setElementTop] = useState(0);
    const ref = useRef(null);
    const { scrollY } = useViewportScroll();
    const mobile = useMediaQuery('(max-width:1025px)')
    // Accessibility check
    const shouldReduceMotion = useReducedMotion();

    let y;
    if (typeof window !== 'undefined') {
        // TO DO: The values of the transform are key to correctly move the Parallax
        y = useTransform(
            scrollY,
            [elementTop, window.innerHeight],
            [5, 0],
            { clamp: false },
        );
    }
    // for mobile: [-140, 224]
    useLayoutEffect(() => {
        setElementTop(ref.current.offsetTop);
    }, [ref]);

    if (mobile && !showMobile) {
      return children;
    }

    return (
        <div ref={ref} className={classes.root}>
            <div className={classes.container}>
              <motion.div
                  className={classes.trail}
                  style={{ y: shouldReduceMotion ? 0 : y }}
              >
                  <TrailSvg />
              </ motion.div>
            </div>
            {children}
        </div>
    );
};

export default withStyles(styles)(Trail);
