import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    gridContainer: {
        margin: '17em 0 17em 28% ',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(769)]: {
          margin: '7em 0 7em 28%',
          flexDirection: 'column-reverse',
        },
        [theme.breakpoints.down(501)]: {
          margin: '0 0 3em 0',
        },
        '& #benefits': {
            '& img': {
                height: 'auto',
                width: 'auto',
                [theme.breakpoints.down(500)]: {
                    height: '35%',
                    width: '100%',
                    marginLeft: '0%',
                    marginTop: '0%',
                }
            },
        },
    },
    textContainer: {
        width: '60%',
        marginRight: '15%',
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '6%',
        },
        [theme.breakpoints.down(769)]: {
            width: '80%',
            marginBottom: '85px',
            marginTop: '40px',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 500,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '6%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '85px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(501)]: {
                    '& #text': {
                        fontSize: '10.86px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(501)]: {
            width: '60%',
            margin: '3em 0 3em 20%',
            '& #text': {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
                width: '75%',
            },
        },
    },
    subtitle: {
        fontWeight:300,
        fontSize: '36px',
        letterSpacing: '3.21px',
        lineHeight: '50px',
        color: theme.palette.primary.main,
        marginBottom: '90px',
        width: '80%',
        [theme.breakpoints.down(1000)]: {
            fontSize: '32px',
            letterSpacing: '2.3px',
            lineHeight: '46px',
            marginBottom: '50px',
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '28px',
            letterSpacing: '2px',
            lineHeight: '42px',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '23px',
            letterSpacing: '1.8px',
            lineHeight: '40px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '19.43px',
            letterSpacing: '1.73px',
            lineHeight: '22.86px',
            marginBottom: '45px',
        },
    },
    text: {
        fontSize: '18.3px',
        color: theme.palette.primary.main,
        letterSpacing: '0.9px',
        lineHeight: '26.35px',
        fontWeight: 500,
        [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.34px',
            lineHeight: '16px',
            marginBottom: '.8em',
        },
    },
    straightLine: {
        borderTop: '1px solid #3D4274',
        background: 'none !important',
        marginBottom: '1em',
        [theme.breakpoints.down(501)]: { marginBottom: '.8em', },
    },
});
