import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        height: '750px',
        width: '250px',
        textAlign: 'center',
        marginLeft: '8vw',
        [theme.breakpoints.down(1000)]: {
            width: '100%',
            marginLeft: '0vw',
        },
        [theme.breakpoints.down(769)]: {
            maxWidth: '490px',
        },
        [theme.breakpoints.down(701)]: {
            height: '80vw',
        },
        [theme.breakpoints.down(500)]: {
            width: '225px',
        },
    },
    image: {
        marginBottom: '20px',
        '& img': { marginBottom: 0, width:'250px',},
        [theme.breakpoints.down(701)]: {
            marginBottom: '10px',
        },
        zIndex: -999,
    },
    description: {
        fontFamily: 'MuseoSans, sans-serif',
        fontWeight: 700,
        fontSize: '14.5px',
        color: theme.palette.primary.gray,
        textAlign: 'center',
        letterSpacing: '0.88px',
        letterHeight: '21px',
        marginTop: '10px',
        [theme.breakpoints.down(1300)]: { marginLeft: '0px', },
        [theme.breakpoints.down(1000)]: { fontSize: '13.3px', },
        [theme.breakpoints.down(800)]: {
            fontSize: '12.2px',
            float: 'right',
            marginRight: '0px',
        },
        [theme.breakpoints.down(701)]: {
            letterSpacing: '1.5px',
            marginRight: '0px',
        },
        [theme.breakpoints.down(600)]: { fontSize: '11.8px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '11px',
            letterSpacing: '1.14px',
        },
    },
});
