// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import cuencaCardsLogo from 'images/cuenca_cards_logo.svg';

// local imports
import { styles } from './CuencaCards.style';

function CuencaCards(props: WithStyles<typeof styles>) {
    const { classes } = props;
    return (
      <Box className={classes.root}>
        <Box>
          <img className={classes.image} src={cuencaCardsLogo} alt='Logo Cuenca' />
        </Box>
        <Box>
          <p className={classes.title}>¿Qué es Tarjetas Cuenca?</p>
          <p className={classes.text}>
            Tarjetas Cuenca es una sociedad que fue constituida para darte nuevos
            servicios: una tarjeta de crédito con la que podrás obtener nuevos
            beneficios pensados en tus necesidades.
          </p>
        </Box>
      </Box>
  );
}

export default withStyles(styles)(CuencaCards);
