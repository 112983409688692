import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'MuseoSans, sans-serif',
    margin: '17em 0 17em 30% ',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'auto auto auto auto auto',
    '& #dots-virtual': {
      textAlign: 'center',
      position: 'absolute',
      bottom: '2vh',
      left: '10vw',
      zIndex: 1,
      [theme.breakpoints.down(1000)]: { left: '6vw', },
      [theme.breakpoints.down(770)]: { left: '8vw', },
      [theme.breakpoints.down(501)]: { left: '3.5vw', bottom:'1vh',},
    },
    '& #dots-fisica': {
      textAlign: 'center',
      position: 'absolute',
      bottom: '2vh',
      right: '16.75vw',
      zIndex: 1,
      [theme.breakpoints.down(2300)]: { right: '15.75vw', },
      [theme.breakpoints.down(2100)]: { right: '14.75vw', },
      [theme.breakpoints.down(1900)]: { right: '13.75vw', },
      [theme.breakpoints.down(1700)]: { right: '12vw', },
      [theme.breakpoints.down(1440)]: { right: '10vw', },
      [theme.breakpoints.down(1330)]: { right: '8vw', },
      [theme.breakpoints.down(1220)]: { right: '6vw', },
      [theme.breakpoints.down(1110)]: { right: '5vw', },
      [theme.breakpoints.down(1050)]: { right: '4vw', },
      [theme.breakpoints.down(501)]: { right: '0vw', bottom:'2vh'},
    },
    '& #dots-fisica-full': {
      textAlign: 'center',
      position: 'absolute',
      bottom: '3vh',
      left: '9vw',
      zIndex: 1,
    },
    [theme.breakpoints.down(1000)]: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10em 3em 15em 29%',
      '& #dots-virtual': {
        textAlign: 'center',
        position: 'absolute',
        bottom: '3vh',
        left: '9.5vw',
        zIndex: 1,
      },
      '& #dots-fisica': {
        textAlign: 'center',
        position: 'absolute',
        bottom: '3vh',
        right: '13vw',
        zIndex: 1,
      },
    },
    [theme.breakpoints.down(501)]: { margin: '10em 3em 15em 4.25em', }
  },
  textBox: {
    marginRight: '12vw',
    marginLeft: '5vw',
    [theme.breakpoints.down(501)]: {
      marginRight: '8vw',
      marginLeft: '8vw',
    }
  },
  inline: {
    fontWeight: 900,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    color: theme.palette.primary.gray,
    position: 'absolute',
    left: '38.5vw',
    display:'inline-flex',
    '& .left': {
      marginRight: '80px',
      [theme.breakpoints.down(501)]: { marginRight: '40px', },
    },
    '& .active': {
      color: theme.palette.primary.dark,
    },
    '& p': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(1000)]: { left: '49.5vw',},
    [theme.breakpoints.down(900)]: { left: '48.25vw',},
    [theme.breakpoints.down(860)]: { left: '45.5vw', },
    [theme.breakpoints.down(740)]: { left: '44vw', },
    [theme.breakpoints.down(620)]: { left: '40vw', },
    [theme.breakpoints.down(570)]: { left: '37%', },
    [theme.breakpoints.down(501)]: {
      left: '36vw',
      fontSize: '11px',
      letterSpacing: '1.03px'
    },
    [theme.breakpoints.down(400)]: { left: '31vw', },
  },
  carousel: {
    overflow: 'hidden',
    height: '650px',
    left: '8vw',
    [theme.breakpoints.down(1000)]: {
      order: 2,
      height: '620px',
      width: '300px',
      marginLeft: '0em',
      marginTop: '70px',
      alignSelf: 'center',
    },
    [theme.breakpoints.down(770)]: {
      height: '650px',
      width: '300px',
    },
    [theme.breakpoints.down(501)]: {
      height: '565px',
      width: '225px',
      marginTop: '50px',
    }
  },
  title: {
    fontWeight:300,
    fontSize: '36px',
    letterSpacing: '3.21px',
    lineHeight: '50px',
    color: theme.palette.primary.main,
    marginBottom:'10vh',
    [theme.breakpoints.down(1000)]: {
      fontSize: '32px',
      marginBottom:'5vh',
    },
    [theme.breakpoints.down(800)]: { fontSize: '28px', },
    [theme.breakpoints.down(600)]: { fontSize: '23px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '19.43px',
      letterSpacing: '1.73px',
      lineHeight: '22.86px',
    }
  },
  subtitle: {
    fontWeight:700,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    lineHeight: '29px',
    color: theme.palette.primary.gray,
    marginBottom:'6.5vh',
    [theme.breakpoints.down(1000)]: { marginBottom:'4vh', fontSize: '16.3px',},
    [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
    [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '10.9px',
      letterSpacing: '1.02px',
      lineHeight: '16px',
    }
  },
  text: {
    fontWeight:500,
    fontSize: '18.3px',
    letterSpacing: '0.9px',
    lineHeight: '26.35px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
    [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
    [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '10.86px',
      letterSpacing: '0.34px',
      lineHeight: '16px',
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
  },
});
