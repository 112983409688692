// React imports
import React, { useState } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Carousel from '@cuenca-mx/tamayo/dist/Carousel';
import ImageCarousel from 'components/ImageCarousel';

// local imports
import { styles } from './CurrentClients.style';

function CurrentClients(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;
    const [isVirtual, setVirtual] = useState(false);
    let imagesData;
    if (isFullLicense) {
      imagesData = {
        'virtual': [
            (style) => <ImageCarousel type='full_current_virtual_1' style={style} />,
            (style) => <ImageCarousel type='full_current_virtual_2' style={style} />,
            (style) => <ImageCarousel type='full_current_virtual_3' style={style} />,
            (style) => <ImageCarousel type='full_current_virtual_4' style={style} />,
            (style) => <ImageCarousel type='full_current_virtual_5' style={style} />,
            (style) => <ImageCarousel type='full_current_virtual_6' style={style} />,
        ],
        'fisical': [
            (style) => <ImageCarousel type='full_current_fisical_1' style={style} />,
            (style) => <ImageCarousel type='full_current_fisical_2' style={style} />,
            (style) => <ImageCarousel type='full_current_fisical_3' style={style} />,
            (style) => <ImageCarousel type='full_current_fisical_4' style={style} />,
            (style) => <ImageCarousel type='full_current_fisical_5' style={style} />,
            (style) => <ImageCarousel type='full_current_fisical_6' style={style} />,
        ]
      };
    } else {
      imagesData = {
        'virtual': [],
        'fisical': [
            (style) => <ImageCarousel type='current_fisical_1' style={style} />,
            (style) => <ImageCarousel type='current_fisical_2' style={style} />,
            (style) => <ImageCarousel type='current_fisical_3' style={style} />,
            (style) => <ImageCarousel type='current_fisical_4' style={style} />,
            (style) => <ImageCarousel type='current_fisical_5' style={style} />,
            (style) => <ImageCarousel type='current_fisical_6' style={style} />,
            (style) => <ImageCarousel type='current_fisical_7' style={style} />,
        ]
      };
    }
    return (
      <Box className={classes.root}>
        <Box>
          <p className={classes.title}>Clientes actuales</p>
          <p className={classes.subtitle}>
            SÚMATE A LA TRAVESÍA
            { isFullLicense ?
              " TUS TARJETAS ACTUALES":
              " Y SOLICITA TU TARJETA FÍSICA DE CRÉDITO"
            }
          </p>
          <p className={classes.text}>
            { isFullLicense ?
              "Es necesario que realices el cambio de tu tarjeta virtual como de la física durante los meses de abril a mayo desde tu app." :
              "Desde el 20 de mayo podrás acceder a los nuevos beneficios solicitando tu nueva tarjeta física de crédito a través de la app de Cuenca. Cualquier duda comunícate con nosotros a través de Soporte."
            }
          </p>
          <p className={classes.text}>
            { isFullLicense ?
              "Está al pendiente de la notificación, si solicitas tu tarjeta física de crédito Mastercard en los primeros 7 días después de recibirla, podrás tener acceso a la edición especial: “Travesía”." :
              "Está al pendiente de la notificación, si solicitas tu tarjeta física de crédito Mastercard en los primeros 7 días después de recibirla, podrás tener acceso a la edición especial: “Travesía”."
            }
          </p>
        </Box>
        <Box className={classes.carousel}>
          { isVirtual && (<Carousel
            dataPerPage={imagesData['virtual'].length}
            carouselSlides={imagesData['virtual']}
            />)}
          { !isVirtual && (<Carousel
              dataPerPage={imagesData['fisical'].length}
              carouselSlides={imagesData['fisical']}
            />)
          }
          { isFullLicense && (<Box className={classes.inline}>
              <p className={isVirtual ? 'active left': 'left'} onClick= {() => setVirtual(true)}>
                VIRTUAL
              </p>
              <p className={isVirtual ? null: 'active'} onClick= {() => setVirtual(false)}>
                FÍSICA
              </p>
            </Box>)
          }
        </Box>
      </Box>
  );
}

export default withStyles(styles)(CurrentClients);
