// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './ImageCarousel.style';
import current_fisical_1 from './images/actuales_fisica_1.gif';
import current_fisical_2 from './images/actuales_fisica_2.gif';
import current_fisical_3 from './images/actuales_fisica_3.gif';
import current_fisical_4 from './images/actuales_fisica_4.gif';
import current_fisical_5 from './images/actuales_fisica_5.gif';
import current_fisical_6 from './images/actuales_fisica_6.gif';
import current_fisical_7 from './images/actuales_fisica_7.gif';
import full_current_fisical_1 from './images/full_actuales_fisica_1.gif';
import full_current_fisical_2 from './images/full_actuales_fisica_2.gif';
import full_current_fisical_3 from './images/full_actuales_fisica_3.gif';
import full_current_fisical_4 from './images/full_actuales_fisica_4.gif';
import full_current_fisical_5 from './images/full_actuales_fisica_5.gif';
import full_current_fisical_6 from './images/full_actuales_fisica_6.gif';
import full_current_virtual_1 from './images/full_actuales_virtual_1.gif';
import full_current_virtual_2 from './images/full_actuales_virtual_2.gif';
import full_current_virtual_3 from './images/full_actuales_virtual_3.gif';
import full_current_virtual_4 from './images/full_actuales_virtual_4.gif';
import full_current_virtual_5 from './images/full_actuales_virtual_5.gif';
import full_current_virtual_6 from './images/full_actuales_virtual_6.gif';
import full_new_fisical_1 from './images/full_nuevos_fisica_1.gif';
import full_new_fisical_2 from './images/full_nuevos_fisica_2.gif';
import full_new_fisical_3 from './images/full_nuevos_fisica_3.gif';
import full_new_fisical_4 from './images/full_nuevos_fisica_4.gif';
import full_new_fisical_5 from './images/full_nuevos_fisica_5.gif';
import full_new_fisical_6 from './images/full_nuevos_fisica_6.gif';
import full_new_fisical_7 from './images/full_nuevos_fisica_7.gif';
import full_new_fisical_8 from './images/full_nuevos_fisica_8.gif';
import full_new_virtual_1 from './images/full_nuevos_virtual_1.gif';
import full_new_virtual_2 from './images/full_nuevos_virtual_2.gif';
import full_new_virtual_3 from './images/full_nuevos_virtual_3.gif';
import full_new_virtual_4 from './images/full_nuevos_virtual_4.gif';
import full_new_virtual_5 from './images/full_nuevos_virtual_5.gif';
import full_new_virtual_6 from './images/nuevos_virtual_6.gif';
import new_fisical_1 from './images/nuevos_fisica_1.gif';
import new_fisical_2 from './images/nuevos_fisica_2.gif';
import new_fisical_3 from './images/nuevos_fisica_3.gif';
import new_fisical_4 from './images/nuevos_fisica_4.gif';
import new_fisical_5 from './images/nuevos_fisica_5.gif';
import new_fisical_6 from './images/nuevos_fisica_6.gif';
import new_fisical_7 from './images/nuevos_fisica_7.gif';
import new_fisical_8 from './images/nuevos_fisica_8.gif';
import new_virtual_1 from './images/nuevos_virtual_1.gif';
import new_virtual_2 from './images/nuevos_virtual_2.gif';
import new_virtual_3 from './images/nuevos_virtual_3.gif';
import new_virtual_4 from './images/nuevos_virtual_4.gif';
import new_virtual_5 from './images/nuevos_virtual_5.gif';
import new_virtual_6 from './images/nuevos_virtual_6.gif';

type ImageCarouselProps = {
    type: string;
    style: object;
} & typeof defaultProps;

const defaultProps = {
    type: 'current_fisical_1',
};

const DATA_SOURCE = {
    'current_fisical_1': [current_fisical_1, 'CONOCE ACERCA DE TARJETAS CUENCA Y LOS BENEFICIOS QUE OFRECE TU NUEVA TARJETA FÍSICA.'],
    'current_fisical_2': [current_fisical_2, 'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA.'],
    'current_fisical_3': [current_fisical_3, 'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.'],
    'current_fisical_4': [current_fisical_4, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    'current_fisical_5': [current_fisical_5, 'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.'],
    'current_fisical_6': [current_fisical_6, 'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.'],
    'current_fisical_7': [current_fisical_7, '¡LISTO! COMPLETASTE TU SOLICITUD. RECUERDA, PUEDE TARDAR HASTA 7 DÍAS HÁBILES EN LLEGAR.'],
    'new_fisical_1': [new_fisical_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'new_fisical_2': [new_fisical_2, 'CONOCE LOS BENEFICIOS QUE OFRECE TU TARJETA FÍSICA.'],
    'new_fisical_3': [new_fisical_3, 'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA.'],
    'new_fisical_4': [new_fisical_4, 'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.'],
    'new_fisical_5': [new_fisical_5, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    'new_fisical_6': [new_fisical_6, 'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.'],
    'new_fisical_7': [new_fisical_7, 'SELECCIONA EL HORARIO EN EL QUE DESEAS RECIBIR TU TARJETA.'],
    'new_fisical_8': [new_fisical_8, '¡LISTO! COMPLETASTE TU SOLICITUD. RECIBIRÁS UNA NOTIFICACIÓN CUANDO ESTÉ EN CAMINO.'],
    'new_virtual_1': [new_virtual_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'new_virtual_2': [new_virtual_2, 'SELECCIONA EL TIPO DE TARJETA VIRTUAL QUE DESEAS TENER. RECUERDA QUE NO PODRÁS CAMBIARLA UNA VEZ SOLICITADA.'],
    'new_virtual_3': [new_virtual_3, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    'new_virtual_4': [new_virtual_4, 'CONOCE ACERCA DE TARJETAS CUENCA.'],
    'new_virtual_5': [new_virtual_5, 'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.'],
    'new_virtual_6': [new_virtual_6, '¡LISTO! YA PUEDES USAR TU TARJETA VIRTUAL. SIEMPRE PODRÁS CONSULTAR TUS DATOS DESDE LA APP.'],
    'full_current_fisical_1': [full_current_fisical_1, 'CONOCE LOS BENEFICIOS QUE OFRECE TU NUEVA TARJETA FÍSICA.'],
    'full_current_fisical_2': [full_current_fisical_2, 'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA. '],
    'full_current_fisical_3': [full_current_fisical_3, 'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.'],
    'full_current_fisical_4': [full_current_fisical_4, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    'full_current_fisical_5': [full_current_fisical_5, 'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.'],
    'full_current_fisical_6': [full_current_fisical_6, '¡LISTO! COMPLETASTE TU SOLICITUD. RECUERDA, PUEDE TARDAR HASTA 7 DÍAS HÁBILES EN LLEGAR. '],
    'full_current_virtual_1': [full_current_virtual_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'full_current_virtual_2': [full_current_virtual_2, 'SELECCIONA EL TIPO DE TARJETA VIRTUAL QUE DESEAS TENER. RECUERDA QUE NO PODRÁS CAMBIARLA UNA VEZ SOLICITADA.'],
    'full_current_virtual_3': [full_current_virtual_3, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    'full_current_virtual_4': [full_current_virtual_4, 'CONOCE ACERCA DE TARJETAS CUENCA.'],
    'full_current_virtual_5': [full_current_virtual_5, 'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU REEMPLAZO.'],
    'full_current_virtual_6': [full_current_virtual_6, '¡LISTO! HAZ REEMPLAZADO TU TARJETA VIRTUAL. ACTUALIZA TUS PAGOS RECURRANTES. CONTINUA EL PROCESO Y REEMPLAZA TU TARJETA FÍSICA.'],
    'full_new_fisical_1': [full_new_fisical_1, 'CONOCE LOS BENEFICIOS QUE OFRECE TU TARJETA FÍSICA.'],
    'full_new_fisical_2': [full_new_fisical_2, 'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA. '],
    'full_new_fisical_3': [full_new_fisical_3, 'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.'],
    'full_new_fisical_4': [full_new_fisical_4, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    'full_new_fisical_5': [full_new_fisical_5, 'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.'],
    'full_new_fisical_6': [full_new_fisical_6, 'SELECCIONA EL HORARIO EN EL QUE DESEAS RECIBIR TU TARJETA.'],
    'full_new_fisical_7': [full_new_fisical_7, 'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.'],
    'full_new_fisical_8': [full_new_fisical_8, '¡LISTO! COMPLETASTE TU SOLICITUD. RECIBIRÁS UNA NOTIFICACIÓN CUANDO ESTÉ EN CAMINO.'],
    'full_new_virtual_1': [full_new_virtual_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    'full_new_virtual_2': [full_new_virtual_2, 'SELECCIONA EL TIPO DE TARJETA VIRTUAL QUE DESEAS TENER. RECUERDA QUE NO PODRÁS CAMBIARLA UNA VEZ SOLICITADA.'],
    'full_new_virtual_3': [full_new_virtual_3, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    'full_new_virtual_4': [full_new_virtual_4, 'CONOCE ACERCA DE TARJETAS CUENCA.'],
    'full_new_virtual_5': [full_new_virtual_5, 'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.'],
    'full_new_virtual_6': [full_new_virtual_6, '¡LISTO! YA PUEDES USAR TU TARJETA VIRTUAL. SIEMPRE PODRÁS CONSULTAR TUS DATOS DESDE LA APP.'],
};

function ImageCarousel(
    props: ImageCarouselProps & WithStyles<typeof styles>
) {
    const { classes, type, ...rest } = props;
    const data = DATA_SOURCE[type]

    return (
        <Box className={classes.root} {...rest}>
            <Box className={classes.image}>
                <img src={data[0]} alt='Random Image Placeholder' />
            </Box>

            <p className={classes.description}>
                {data[1]}
            </p>
        </Box>
    );
}
ImageCarousel.defaultProps = defaultProps;

export default withStyles(styles)(ImageCarousel);
