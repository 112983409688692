import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    gridContainer: {
        fontFamily: 'MuseoSans, sans-serif',
        margin: '17em 0 17em 0',
        display: 'flex',
        flexDirection: 'row',
        '& #mcFisica': {
            '& img': {
            height: 'auto',
            width: 'auto',
            },
        },
        [theme.breakpoints.down(1000)]: {
            width: '100%',
            '& #mcFisica': {
                '& img': { width: '90% !important', },
            },
        },
        [theme.breakpoints.down(769)]: {
            flexDirection: 'column',
            '& #mcFisica': {
                '& img': { width: '100% !important', },
            },
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '200px',
            marginTop: '150px',
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '60px',
            marginTop: '60px',
            marginLeft: '0px',
            '& #mcFisica': {
                '& img': {
                    height: '236px !important',
                    width: '323px !important',
                    maxWidth: '350px',
                },
            },
        },
    },
    textContainer: {
        width: '45%',
        '& #text': {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.45px',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '8%',
        },
        [theme.breakpoints.down(1200)]: {
            marginRight: '4%',
        },
        [theme.breakpoints.down(769)]: {
            marginRight: '8%',
            width: '100%',
            marginLeft: '15%',
            marginBottom: '93px',
            textContainer: {
                marginRight: '16.87%',
                '& #text': {
                    fontSize: '18.33px',
                    fontWeight: 500,
                    color: '#3D4274',
                    letterSpacing: '0.75px',
                    lineHeight: '26.45px',
                },
                [theme.breakpoints.down(1200)]: {
                    marginRight: '8%',
                },
                [theme.breakpoints.down(769)]: {
                    marginBottom: '93px',
                    marginRight: '0',
                    '& #text': {
                        fontSize: '21px',
                        letterSpacing: '0.75px',
                        lineHeight: '30px',
                    },
                },
                [theme.breakpoints.down(701)]: {
                    '& #text': {
                        fontSize: '19px',
                        letterSpacing: '0.34px',
                        lineHeight: '28px',
                    },
                },
                [theme.breakpoints.down(650)]: {
                    '& #text': {
                        fontSize: '16px',
                        letterSpacing: '0.5px',
                        lineHeight: '26px',
                    },
                },
                [theme.breakpoints.down(501)]: {
                    marginBottom: '53px',
                    '& #text': {
                        fontSize: '10.86px',
                        letterSpacing: '0.45px',
                        lineHeight: '16px',
                    },
                },
            },            '& #text': {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
        },
        [theme.breakpoints.down(701)]: {
            '& #text': {
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
        },
        [theme.breakpoints.down(650)]: {
            '& #text': {
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
        },
        [theme.breakpoints.down(501)]: {
            width: '65%',
            marginLeft: '40%',
            marginRight: '0%',
            marginBottom: '0px',
            '& #text': {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
                width: '75%',
            },
        },
    },
    subSectionIndex: {
        width: '50%',
        fontSize: '18.31px',
        fontWeight: 700,
        lineHeight: '29px',
        letterSpacing: '1.71px',
        color: '#9BA0B7',
        flexDirection: 'column',
        marginBottom: '60px',
        [theme.breakpoints.down(1200)]: {
          margin: '0 0 0 22% !important',
          fontSize: '17.3px',
          width: '80%',
        },
        [theme.breakpoints.down(1000)]: {
          marginLeft: '0% !important',
          marginBottom:'4vh',
          fontSize: '16.3px',
          width: '100%',
        },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(769)]: {
            marginTop: '60px !important',
            marginLeft: '28% !important',
            width: '50%',
        },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.9px',
            letterSpacing: '1.02px',
            lineHeight: '16px',
            marginBottom: '40px',
            marginLeft: '5% !important',
            marginTop: '40px !important'
        },
    },
    text: {
        fontFamily: 'MuseoSans, sans-serif',
        fontSize: '18.3px',
        color: '#3D4274',
        letterSpacing: '0.75px',
        lineHeight: '26.35px',
        fontWeight: 500,
        margin: '0 0 0 28%',
        width: '50%',
        [theme.breakpoints.down(1200)]: {
          margin: '0 0 0 22%',
          fontSize: '17.3px',
          width: '80%',
        },
        [theme.breakpoints.down(1000)]: {
          fontSize: '16.3px',
          marginLeft: '0%',
          width: '100%',
        },
        [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
        [theme.breakpoints.down(769)]: {
            marginLeft: '28%',
            width: '50%',
        },
        [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.34px',
            lineHeight: '16px',
            margin: '0 0 0 5%',
            width: '75%',
        },
    },
    specialText: {
      fontFamily: 'MuseoSans, sans-serif',
      fontWeight: 500,
      color: '#3D4274',
      width: '35%',
      marginBottom: '2.5em',
      fontSize: '19px',
      letterSpacing: '1.7px',
      lineHeight: '30px',
      margin: '0 0 0 28%',
      [theme.breakpoints.down(1200)]: {
        marginLeft: '22%',
        width: '50%',
        fontSize: '17px',
      },
      [theme.breakpoints.down(1000)]: {
        marginLeft: '0%',
        fontSize: '15px',
        width: '70%',
      },
      [theme.breakpoints.down(800)]: { fontSize: '13px', },
      [theme.breakpoints.down(769)]: {
          marginLeft: '28%',
          width: '30%',
      },
      [theme.breakpoints.down(600)]: { fontSize: '11px', },
      [theme.breakpoints.down(501)]: {
        fontSize: '9.14px',
        letterSpacing: '0.82px',
        lineHeight: '14.14px',
        marginLeft: '5%',
      },
    },
    amountText: {
      fontFamily: 'MuseoSans, sans-serif',
      fontSize: '100px',
      color: '#3D4274',
      letterSpacing: '0.75px',
      lineHeight: '26.35px',
      fontWeight: 500,
      margin: '0 0 0 28%',
      width: '50%',
      [theme.breakpoints.down(1200)]: {
        marginLeft: '22%',
        fontSize: '92px',
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: '87px',
        marginLeft: '0%',
        width: '70%',
      },
      [theme.breakpoints.down(800)]: { fontSize: '79px', },
      [theme.breakpoints.down(769)]: {
          marginLeft: '28%',
          width: '50%',
      },
      [theme.breakpoints.down(600)]: { fontSize: '71px', },
      [theme.breakpoints.down(501)]: {
        fontSize: '68.57px',
        letterSpacing: '-0.3px',
        marginLeft: '5%',
      },
    },
});
