import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'MuseoSans, sans-serif',
    margin: '17em 0 17em 28% ',
    display: 'grid',
    gridTemplateColumns: '35% 65%',
    gridTemplateRows: 'auto auto auto auto auto',
    '& #dots-undefined': {
      position: 'absolute',
      bottom: '1vh',
      left: '8.75vw',
      zIndex: 1,
      [theme.breakpoints.down(1350)]: { left: '8.9vw', },
      [theme.breakpoints.down(1000)]: {
        left: '5vw',
        bottom: '20px',
      },
      [theme.breakpoints.down(700)]: {
        left: '5.5vw',
        bottom: '60px',
      },
      [theme.breakpoints.down(600)]: { left: '12%' },
      [theme.breakpoints.down(501)]: { left: '0%', bottom: '10px'},
      [theme.breakpoints.down(300)]: { bottom: '37px', },
    },
    [theme.breakpoints.down(1200)]: { gridTemplateColumns: '35% 75%', },
    [theme.breakpoints.down(1000)]: {
      margin: '10em 3em 15em 29%',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down(501)]: { margin: '7em 4.5em 7em 6.75em', }
  },
  inline: {
    fontWeight: 900,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    color: theme.palette.primary.gray,
    position: 'absolute',
    right: '22.5vw',
    display:'inline-flex',
    '& .left': {
      marginRight: '6vw',
    },
    '& .active': {
      color: theme.palette.primary.dark,
    },
    '& p': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(501)]: {
      fontSize: '11px',
      letterSpacing: '1.03px'
    }
  },
  carousel: {
    width: '65%',
    overflow: 'hidden',
    height: '650px',
    [theme.breakpoints.down(1000)]: {
      marginTop: '70px',
      overflow: 'none',
      height: '620px',
      width: '300px',
      alignSelf: 'center',
    },
    [theme.breakpoints.down(770)]: {
      height: '650px',
      alignSelf: 'center',
    },
    [theme.breakpoints.down(501)]: {
      marginTop: '40px',
      marginLeft: '4%',
      height: '565px',
      width: '225px',
      alignSelf: 'center',
    }
  },
  classCarousel: {
    left: '6.75vw',
  },
  title: {
    fontWeight:300,
    fontSize: '36px',
    letterSpacing: '3.21px',
    lineHeight: '50px',
    color: theme.palette.primary.main,
    marginBottom:'10vh',
    [theme.breakpoints.down(1000)]: {
      fontSize: '32px',
      marginBottom:'5vh',
    },
    [theme.breakpoints.down(800)]: { fontSize: '28px', },
    [theme.breakpoints.down(600)]: { fontSize: '23px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '19.43px',
      letterSpacing: '1.73px',
      lineHeight: '22.86px',
      marginBottom:'40px',
    }
  },
  subtitle: {
    fontWeight:700,
    fontSize: '18.31px',
    letterSpacing: '1.71px',
    lineHeight: '29px',
    color: theme.palette.primary.gray,
    marginBottom:'6.5vh',
    [theme.breakpoints.down(1000)]: { marginBottom:'4vh', fontSize: '16.3px',},
    [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
    [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '10.9px',
      letterSpacing: '1.02px',
      lineHeight: '16px',
      marginBottom:'40px',
    }
  },
  text: {
    fontWeight:500,
    fontSize: '18.3px',
    letterSpacing: '0.9px',
    lineHeight: '26.35px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(1000)]: { fontSize: '16.3px', },
    [theme.breakpoints.down(800)]: { fontSize: '14.3px', },
    [theme.breakpoints.down(600)]: { fontSize: '12.3px', },
    [theme.breakpoints.down(501)]: {
      fontSize: '10.8px',
      letterSpacing: '0.6px',
      lineHeight: '16px',
    }
  }
});
