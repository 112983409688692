// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import logoUnion from 'images/logos_union.png';

import { styles } from './Union.style'

function Union(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.gridContainer}>
            <Box className={classes.textContainer}>
                <p className={classes.subtitle}>
                    Nuevas rutas, más beneficios
                </p>
                <p className={classes.subSectionIndex}>
                    GENERA HISTORIAL CREDITICIO Y MÁS
                </p>
                <p className={classes.text}>
                    Esta unión nos permite ofrecerte: generar un historial crediticio, aumentar los
                    establecimientos que aceptan la tarjeta, renta de autos, agregar más opciones
                    de pagos, domiciliación de servicios y más. Todo ello para ayudar a Cuenca a
                    ofrecerte nuevos servicios. 
                </p>
            </Box>

            <Box id='logosUnion'>
                <img src={logoUnion} alt='TarjetasCuenca + MasterCard + Cuenca'/>
            </Box>
        </Box>
    )
};

export default withStyles(styles)(Union);
