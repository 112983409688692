// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { styles } from './Continue.style'

function Continue(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const reduceMargin = useMediaQuery('(min-width:600px)');

    return (
      <Box className={classes.root}>
        <p>
          ¡Continúa siendo parte de nuestra travesía con Cuenca!
        </p>
      </Box>
    )
}

export default withStyles(styles)(Continue);
