import { Theme, createStyles } from '@material-ui/core/styles';
import 'fonts/museoSans.css';

export const styles = (theme: Theme) => createStyles({
    root: {
        display: 'block',
        '& iframe': {
          height: '720px',
        },
        [theme.breakpoints.down(1000)]: {
          '& iframe': {
            height: '550px',
          },
        },
        [theme.breakpoints.down(501)]: {
          '& iframe': {
            height: '300px',
          },
        }
    },
});
