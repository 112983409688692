// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import tarjetaVirtual from 'images/MC_tarjeta_credito_debito.png';

import { styles } from './McVirtual.style'

function McVirtual(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;

    return (
        <Box className={classes.gridContainer}>
            <Box className={classes.textContainer}>
                <p className={classes.subSectionIndex}>
                    TU {isFullLicense && "NUEVA"} TARJETA VIRTUAL
                </p>
                <p className={classes.text} style={{
                    width: '60%'
                }}>
                    {isFullLicense ?
                      "Tu tarjeta virtual se puede mantener de débito o crédito, esto depende de tu preferencia en cada caso. Tu tarjeta física ahora será de crédito." :
                      "Tu tarjeta virtual permanece como hasta ahora, pero está al pendiente de nuevos beneficios. Estamos dando pasos para estar más cerca de ti."
                    }
                </p>
            </Box>


            <Box id='mcVirtual'>
                <img src={tarjetaVirtual} alt='TarjetasCuenca + MasterCard + Cuenca'/>
            </Box>

        </Box>
    )

}

export default withStyles(styles)(McVirtual);
