// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import { styles } from './Replace.style';

function Replace(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;

    return (
        <Box className={classes.gridContainer} style={{
            padding: '20px 0px 20px 20px',
            borderRadius: '1px',
            borderTop: '1px',
            borderLeft: '1px',
            borderBottom: '1px',
            borderRight: '0px',
            borderColor: '#3D4274',
            borderStyle: 'solid',
            backgroundColor: '#ffffff',
        }}>
            <Box className={classes.textContainer} style={{
                marginLeft: '10%',
            }}>
                <p className={classes.specialText}>
                    A PARTIR DEL
                </p>
                <p className={classes.bigText}>
                    20
                </p>
                <p className={classes.specialText} style={{
                    marginTop: '12%',
                }}>
                    DE MAYO PODRÁS SOLICITAR TU TARJETA FÍSICA DE CRÉDITO
                </p>
            </Box>
            <Box id='replaceText' className={classes.textContainer} style={{ marginRight: '0%', }}>
                <p className={classes.text} style={{
                    marginTop: '5%',
                    marginBottom: '1em',
                    width: '85%',
                }}>
                    Si realizas el cambio de {isFullLicense ? "ambas tarjetas" : "la tarjeta física"} durante el período indicado
                    podrás elegir entre la tarjeta clásica o bien, la edición especial
                    “Travesía” (edición limitada a existencia).
                </p>
                <p className={classes.straightLine}/>
                <p className={classes.text} style={{
                    marginTop: '5%',
                    marginBottom: '1em',
                    width: '85%'
                }}>
                  {isFullLicense ?
                    "Una vez que seas notificado, tendrás 30 días para realizar el cambio, ya que tus tarjetas anteriores serán desactivadas. Todo desde tu app.":
                    "Esta alianza nos ayuda a estar más cerca de ti y a brindarte nuevos servicios."
                  }
                </p>
            </Box>
        </Box>
    )
}

export default withStyles(styles)(Replace);
