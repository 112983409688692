import React from 'react'

import Box from '@material-ui/core/Box';

import Trail from 'components/Trail';
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';
import Header from 'views/Travesia/Header';
import Union from 'views/Travesia/Union';
import MCFisica from 'views/Travesia/McFisica';
import Replace from 'views/Travesia/Replace';
import McVirtual from 'views/Travesia/McVirtual';
import Benefits from 'views/Travesia/Benefits';
import Continue from 'views/Travesia/Continue';
import CurrentClients from 'views/Travesia/CurrentClients';
import NewClients from 'views/Travesia/NewClients';
import CuencaCards from 'views/Travesia/CuencaCards';
import Video from 'views/Travesia/Video';

// TODO: Use the real assets
import SEO from 'components/seo';
import thisIsFine from 'images/videos/tmpVideoAsset.mp4';
import travesiaCard from 'images/product_shot_travesia.jpg';
import travesiaCardMobile from 'images/product_shot_travesia_mob.jpg';
import { useMediaQuery } from '@material-ui/core';

const TravesiaPage = () => {

  const maxSize = !useMediaQuery('(max-width:1100px)')
  const pushToLeft = useMediaQuery('(min-width:600px)')
  const mobile = useMediaQuery('(max-width:500px)')

  const isFullLicense = process.env.LICENSE === 'full-license' ? true : false
  return (
    <WithTheme>
      <Layout>

        <SEO title='Travesía' />

        <Header/>

        <Trail showMobile={false}>
          <Video/>

          <Union/>

          <MCFisica isFullLicense={isFullLicense}/>

          <CurrentClients isFullLicense={isFullLicense}/>

          <Box style={{
              width: '100%',
              marginBottom: '5%',
          }}>
              <img src={mobile ? travesiaCardMobile: travesiaCard} alt='Tarjeta física Cuenca' style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}/>
          </Box>

          <Replace isFullLicense={isFullLicense}/>

          <McVirtual isFullLicense={isFullLicense}/>

          <NewClients isFullLicense={isFullLicense}/>

          <Benefits/>

          <CuencaCards />

          <Continue />
        </Trail>
      </Layout>
    </WithTheme>
  );
};

export default TravesiaPage;
